import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=3b65001a&scoped=true&"
import script from "./detail.vue?vue&type=script&lang=js&"
export * from "./detail.vue?vue&type=script&lang=js&"
import style0 from "./detail.vue?vue&type=style&index=0&id=3b65001a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b65001a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnTab: require('/home/php1/m-nuxt/components/YfnTab.vue').default,YfnSwiper: require('/home/php1/m-nuxt/components/YfnSwiper.vue').default,YfnSticky: require('/home/php1/m-nuxt/components/YfnSticky.vue').default,YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default,YfnButton: require('/home/php1/m-nuxt/components/YfnButton.vue').default,YfnList: require('/home/php1/m-nuxt/components/YfnList.vue').default,YfnCollapse: require('/home/php1/m-nuxt/components/YfnCollapse.vue').default,YfnVideo: require('/home/php1/m-nuxt/components/YfnVideo.vue').default,BizBrand: require('/home/php1/m-nuxt/components/BizBrand.vue').default,BizCategorySeo: require('/home/php1/m-nuxt/components/BizCategorySeo.vue').default})
