

export default {
    name: 'CategoryDetail',
    layout: 'main-head',
    async asyncData({ $api, cookies, ...context }) {
        const [response, categoryR, responseSeo] = await Promise.all([
            $api.category.getCategoryList({
                pageSize: 20,
                pageNum: 1,
                cateSeoUrl: context.params.seoUrl,
                categoryCode: context.params.code,
                needFilter: true,
            }),
            $api.category.getMenu(),
            context?.$query?.page>1?$api.category.getCategoryList({
                pageSize: 20,
                pageNum: context?.$query?.page,
                cateSeoUrl: context.params.seoUrl,
                categoryCode: context.params.code,
                needFilter: true,
            }):''
        ])
        const res = response?.result || {
            filter: {},
            list: []
        };
        if(!res.list){
            context.res?context.res.statusCode = 404:''
        }
        const topKeywords = res.similarCategory || []
        const keyWordsFirst = topKeywords[0] || {}
        const tab = {
            id: '',
            visible: !!topKeywords?.length,
            list: topKeywords
        }
        const faqs = res.faqs || []
        const collapseCompo = {
            visible: !!faqs?.length,
            hasContent: true,
            list: faqs,
            key: {
                title: 'question',
                name: 'question',
                content: 'answer'
            }
        }
        // 分组导航
        const categorySeo = categoryR?.result || []
        return {
            allData: res,
            tab,
            collapseCompo,
            categorySeo,
            seoData: responseSeo || res,
        }
    },
    data() {
        return {
            skeleton: true,
            visible: true,
            allData: {},
            seoData: {},
            clear: '',            // 节流
            query: {},            // 路径参数
            category: {           // 分组tab数据
                visible: false,
                code: '',
                initialSlide: 1,
                list: []
            },
            ad: {
                visible: false,
                list: []
            },
            tab: {
                // visible: false,
                // id: '',
                // list: []
            },
            collapseCompo: {},
            filter: {             // 筛选结果
                num: 0,
                mergeSort: '',
                params: {}
            },
            page: {               // 分页
                pageSize: 20,
                pageNum: 1,
                totalPage: 1
            },
            params: {             // 接口参数
                cateSeoUrl: '',
                categoryId: '',
                categoryCode: ''
            },
            stickyComo: {
                visible: false,
                hasNav: true,
                hasSort: true,
                hasFilter: true,
                hasCloseSticky: true,
                stickyTag: '.Head-box',
                data: {}
            },
            listCompo: {          // list组件配置项
                list: [],
                options: {
                    hasMore: true,
                    hasConcat: true,
                    columns: 2,
                },
                page: {
                    hasMore: true,
                    maxPage: 3,
                    totalPage: 0
                }
            },
            isParentId: false,
        }
    },
    head() {
        return this.setHead()
    },
    created() { },
    mounted() {
        this.initialSet()
        this.setListData()
        if (this.query.type == 1) {
            this.getCategoryAjax()
        } else {
            this.stickyComo.visible = true
        }
        this.getAdAjax()

        let addItems = [];
        this.allData.list?.forEach((cart,index)=>{
            addItems.push({
                item_id: cart.spuModel.id,
                group_item_id: cart.spuModel.productCode,
                item_name: cart.spuModel.productName,
                quantity: 1,
                price: (cart.spuModel.salePrice/100).toFixed(2),
                affiliation: 'YFN',
                item_brand: 'YFN',
                index: index,
            })
        })
        setTimeout(() => {
            this.$fnApi.buried.gtag({
                type: 'view_item_list',
                params: {
                    item_list_id: 'category_list',
                    item_list_name:this.allData.categoryName,
                    items: addItems
                }
            })
        }, 500);
    },
    methods: {
        // 获取所属分组
        getCategoryAjax() {
            this.$api.category.getCategoryGroup({
                cateSeoUrl: this.params.cateSeoUrl,
                categoryId: this.params.categoryId,
                categoryCode: this.params.categoryCode,
            }).then(response => {
                let res = response.result || {};
                this.category.list = res || []
                this.category.visible = this.category?.list?.length
                this.category.list.some((item, i) => {
                    if (item.categoryCode == this.params.categoryCode) {
                        this.category.code = item.categoryCode
                        this.category.initialSlide = i
                        return true
                    }
                })
                this.stickyComo.visible = true
                if (this.query.children == 1) {
                    this.params.categoryId = res[0].parentId
                    this.isParentId = true
                    this.category.initialSlide = 0
                    this.category.code = ''
                    this.getIndexAjax()
                }
            })
        },
        // 获取列表数据
        getIndexAjax() {
            this.$api.category.getCategoryList({
                pageSize: this.page.pageSize,
                pageNum: this.page.pageNum,
                cateSeoUrl: this.params.cateSeoUrl,
                categoryId: this.isParentId ? this.params.categoryId : '',
                categoryCode: this.isParentId ? '' : this.params.categoryCode,
                sortColumn: this.filter.mergeSort,
                needFilter: true,
                ...this.filter.params,
            }, false).then(response => {
                let res = response.result || {}
                this.allData = res;
                this.setListData()
            })
        },
        setListData() {
            // 筛选
            this.stickyComo.data = this.allData.filter || []
            this.stickyComo.data.total = this.allData.total
            this.page.totalPage = this.allData.totalPage
            this.listCompo.list = this.allData.list
            this.visible = !!this.listCompo?.list?.length
            let clear = setTimeout(() => {
                this.skeleton = false
                this.setLinkClear()
                clearTimeout(clear)
            }, 200)
            // tab
            const topKeywords = this.allData.similarCategory || []
            const keyWordsFirst = topKeywords[0] || {}
            this.tab.id = '',
            this.tab.visible = !!topKeywords?.length,
            this.tab.list = topKeywords
            // faq
            const faqs = this.allData.faqs || []
            this.collapseCompo.visible = !!faqs?.length,
            this.collapseCompo.list = faqs
            this.listCompo.page.totalPage = this.allData.totalPage
            this.listCompo.page.total = this.allData.total
        },
        // 分组切换
        handleCategory(obj) {
            this.category.initialSlide = obj.$index
            if (obj.categoryCode == this.category.code) {
                this.category.code = ''
                this.isParentId = true
            } else {
                this.isParentId = false
                this.category.code = obj.categoryCode
            }
            this.params.cateSeoUrl = obj.seoUrl
            this.params.categoryCode = obj.categoryCode
            this.page.pageNum = 1
            this.stickyComo.mergeSort = ''
            this.stickyComo.sort = ''
            this.stickyComo.sale = ''
            this.listCompo.options.hasConcat = false
            this.$fnApi.debounce(() => {
                this.getIndexAjax()
            }, 200)
        },
        // 显示筛选项
        handleReselect() {
            const ref = this.$refs.YfnSticky;
            ref && ref.handleFilter()
        },
        // 滚动至顶部
        setScrollTo() {
            this.$VueScrollTo.scrollTo('body')
        },
        // 描述链接去除
        setLinkClear() {
            if(process.client) {
                this.$nextTick(() => {
                    let tag = document.querySelectorAll('.categoryDetail-descDown a') || []
                    tag.forEach(e => {
                        e.target = '_self'
                    })
                })
            }
        },
        // emit
        emitChangeSort(obj) {
            this.listCompo.options.columns = obj.options.columns
        },
        emitChangeFilter(obj) { // filter change
            this.filter.mergeSort = obj.options.mergeSort
            this.filter.params = obj.filterResult.params
            this.filter.num = obj.filterResult.num
            this.listCompo.options.hasConcat = false
            this.page.pageNum = 1
            this.getIndexAjax()
            this.setScrollTo()
        },
        emitBottomLoading(obj, callBack) { // 触底加载更多
            const hasNext = this.page.totalPage > this.page.pageNum;
            if (obj.isIntersecting && hasNext) {
                this.$fnApi.debounce(() => {
                    this.page.pageNum += 1
                    this.listCompo.options.hasConcat = true
                    this.getIndexAjax()
                }, 200)
            }
            callBack(!hasNext)
        },
        // 广告位
        getAdAjax() {
            this.$api.common.getAd({
                places: 16
            }).then(response => {
                const res = response.result || [];
                this.ad.list = [...res]
                this.ad.visible = !!this.ad?.list?.length
            })
        },
        handleAd(obj) {
            window.location.href = obj.jumpUrl
        },
        handleTab(v) {
            const r = this.tab.list.filter(p => {
                return p.categoryName == v
            })
            const first = r[0] || {}
            if(first.seoUrl) {
                window.location.href = '/collection/'+first.seoUrl
            }
        },
        // SEO HEAD
        setHead() {
            const list = this.allData.list || [];
            const first = list[0] || {};
            const pics = first.pics || first?.spuModel?.pics || [];
            const pic = pics[0] || {};
            const head = this.$ctxApi.seoHead.initial({
                title: this.allData.seoTitle,
                keywords: this.allData.seoUrl,
                description: this.allData.seoRemark,
                image: pic.url
            });
            return head
        },
        // 初始化设置
        initialSet() {
            // const language = this.$storage.get('language');
            const reg = new RegExp('.htm', 'g')
            const params = this.$route.params;
            const query = this.$route.query;
            this.query = { ...params, ...query };
            this.params.categoryCode = this.query.code || ''
            this.params.cateSeoUrl = this.query.seoUrl.replace(reg, '') || ''
            // if(['en_US'].includes(language.identify)) {
            //     this.stickyComo.hasLayout = true
            //     this.stickyComo.hasFilter = true
            // }
        }
    },
}
